<template>
    <div class="overview">
        <h3 class="section_title">Leaderboards</h3>
        <div class="quick_status">
            <div class="quick_card">
                <div class="title">
                    <svg width="139" height="127" viewBox="0 0 139 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.3437 64.4958C17.6032 56.936 -0.765599 38.0874 3.68304 23.1714C4.95512 19.2098 10.7704 11.2866 23.8546 11.2866H34.7581H103.886H114.463C120.133 11.2139 132.17 13.4891 134.961 23.1714C138.451 35.2743 127.983 50.2121 111.628 64.4958" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M34.7581 52.8291V11.3958H104.105V52.8291C104.105 74.2873 79.3899 90.9188 67.0326 96.5522C40.0791 81.9851 34.2857 61.3339 34.7581 52.8291Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M67 91L67 109" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M38.7925 120.066V126.609H95.5998V120.066C95.5998 115.531 91.3111 114.542 89.1667 114.615H45.3346C40.0137 114.615 38.7562 118.249 38.7925 120.066Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M33.8857 3H104.868" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                    </svg>
                    Body Composition
                    <ul class="w-100">
                        <li>
                            <select>
                                <option>Nothing tracked</option>
                            </select>
                            <span><i class="fas fa-angle-down"></i></span>
                        </li>
                    </ul>
                </div>
                <div class="card_info">
                    <div class="empty_box">
                        <img src="@/assets/images/empty_habit_tracking.svg" alt="Empty">
                        <p class="text-center">Message your coach to activate this</p>
                    </div>
                </div>
            </div>
            <div class="quick_card">
                <div class="title">
                    <svg width="139" height="127" viewBox="0 0 139 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.3437 64.4958C17.6032 56.936 -0.765599 38.0874 3.68304 23.1714C4.95512 19.2098 10.7704 11.2866 23.8546 11.2866H34.7581H103.886H114.463C120.133 11.2139 132.17 13.4891 134.961 23.1714C138.451 35.2743 127.983 50.2121 111.628 64.4958" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M34.7581 52.8291V11.3958H104.105V52.8291C104.105 74.2873 79.3899 90.9188 67.0326 96.5522C40.0791 81.9851 34.2857 61.3339 34.7581 52.8291Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M67 91L67 109" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M38.7925 120.066V126.609H95.5998V120.066C95.5998 115.531 91.3111 114.542 89.1667 114.615H45.3346C40.0137 114.615 38.7562 118.249 38.7925 120.066Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M33.8857 3H104.868" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                    </svg>
                    Habit Tracking
                    <ul class="w-100">
                        <li>
                            <select>
                                <option>Nothing tracked</option>
                            </select>
                            <span><i class="fas fa-angle-down"></i></span>
                        </li>
                    </ul>
                </div>
                <div class="card_info">
                    <div class="empty_box">
                        <img src="@/assets/images/empty_habit_tracking.svg" alt="Empty">
                        <p class="text-center">Message your coach to activate this</p>
                    </div>
                </div>
            </div>
            <div class="quick_card">
                <div class="title">
                    <svg width="139" height="127" viewBox="0 0 139 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.3437 64.4958C17.6032 56.936 -0.765599 38.0874 3.68304 23.1714C4.95512 19.2098 10.7704 11.2866 23.8546 11.2866H34.7581H103.886H114.463C120.133 11.2139 132.17 13.4891 134.961 23.1714C138.451 35.2743 127.983 50.2121 111.628 64.4958" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M34.7581 52.8291V11.3958H104.105V52.8291C104.105 74.2873 79.3899 90.9188 67.0326 96.5522C40.0791 81.9851 34.2857 61.3339 34.7581 52.8291Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M67 91L67 109" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M38.7925 120.066V126.609H95.5998V120.066C95.5998 115.531 91.3111 114.542 89.1667 114.615H45.3346C40.0137 114.615 38.7562 118.249 38.7925 120.066Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M33.8857 3H104.868" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                    </svg>
                    Client Tracking
                    <ul class="w-100">
                        <li>
                            <select>
                                <option>Nothing tracked</option>
                            </select>
                            <span><i class="fas fa-angle-down"></i></span>
                        </li>
                    </ul>
                </div>
                <div class="card_info">
                    <div class="empty_box">
                        <img src="@/assets/images/empty_habit_tracking.svg" alt="Empty">
                        <p class="text-center">Message your coach to activate this</p>
                    </div>
                </div>
            </div>
            <div class="quick_card">
                <div class="title">
                    <svg width="139" height="127" viewBox="0 0 139 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.3437 64.4958C17.6032 56.936 -0.765599 38.0874 3.68304 23.1714C4.95512 19.2098 10.7704 11.2866 23.8546 11.2866H34.7581H103.886H114.463C120.133 11.2139 132.17 13.4891 134.961 23.1714C138.451 35.2743 127.983 50.2121 111.628 64.4958" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M34.7581 52.8291V11.3958H104.105V52.8291C104.105 74.2873 79.3899 90.9188 67.0326 96.5522C40.0791 81.9851 34.2857 61.3339 34.7581 52.8291Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M67 91L67 109" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M38.7925 120.066V126.609H95.5998V120.066C95.5998 115.531 91.3111 114.542 89.1667 114.615H45.3346C40.0137 114.615 38.7562 118.249 38.7925 120.066Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M33.8857 3H104.868" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                    </svg>
                    Workout Tracking
                    <ul class="w-100">
                        <li>
                            <select>
                                <option>Nothing tracked</option>
                            </select>
                            <span><i class="fas fa-angle-down"></i></span>
                        </li>
                    </ul>
                </div>
                <div class="card_info">
                    <div class="empty_box fx_height">
                        <h2 class="text-center">Coming Soon</h2>
                        <!-- <p class="text-center">Message your coach to activate this</p> -->
                    </div>
                </div>
            </div>
            <div class="quick_card" v-if="1 == 2">
                <div class="title">
                    <svg width="139" height="127" viewBox="0 0 139 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.3437 64.4958C17.6032 56.936 -0.765599 38.0874 3.68304 23.1714C4.95512 19.2098 10.7704 11.2866 23.8546 11.2866H34.7581H103.886H114.463C120.133 11.2139 132.17 13.4891 134.961 23.1714C138.451 35.2743 127.983 50.2121 111.628 64.4958" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M34.7581 52.8291V11.3958H104.105V52.8291C104.105 74.2873 79.3899 90.9188 67.0326 96.5522C40.0791 81.9851 34.2857 61.3339 34.7581 52.8291Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M67 91L67 109" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M38.7925 120.066V126.609H95.5998V120.066C95.5998 115.531 91.3111 114.542 89.1667 114.615H45.3346C40.0137 114.615 38.7562 118.249 38.7925 120.066Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M33.8857 3H104.868" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                    </svg>
                    Body Composition
                    <ul class="w-100">
                        <li>
                            <select v-model="params.progress_id">
                                <option :value="progress.id" v-for="(progress, p) of progresses" :key="p">{{ progress.name }}
                                </option>
                            </select>
                            <span><i class="fas fa-angle-down"></i></span>
                        </li>
                    </ul>
                </div>
                <quote-loader v-if="progressLoader" />
                <ul class="rankboard" v-else>
                    <li v-for="(participant, p) in progressLeaderboard.overallContacts" :key="p">
                        <span class="board_pos">{{ p + 1 }} 
                            <svg width="140" height="118" viewBox="0 0 140 118" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="p < 3">
                                <path d="M0 118V0H140V118L70 61L0 118Z" fill="#2f7eed"/>
                            </svg>
                        </span>
                        <img :src="participant.contact.photo" :alt="participant.contact.name">
                        <h5>{{ participant.contact.name }}</h5>
                        <div class="score_circle">
                            <div class="inner_circle">
                                <div class="score">{{ participant.overall_score }}<span>%</span></div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" :stroke="clientPortal.chart_settings.card_accent_color" :stroke-dasharray="90" :stroke-dashoffset="90 - (participant.overall_score * 0.9)" />
                            </svg>
                        </div>
                    </li>
                    <li v-if="progressLeaderboard.overallContacts && progressLeaderboard.overallContacts.length == 0" class="text-center">
                        No records found!
                    </li>
                </ul>
            </div>
            <div class="quick_card" v-if="1 == 2">
                <div class="title">
                    <svg width="139" height="127" viewBox="0 0 139 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.3437 64.4958C17.6032 56.936 -0.765599 38.0874 3.68304 23.1714C4.95512 19.2098 10.7704 11.2866 23.8546 11.2866H34.7581H103.886H114.463C120.133 11.2139 132.17 13.4891 134.961 23.1714C138.451 35.2743 127.983 50.2121 111.628 64.4958" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M34.7581 52.8291V11.3958H104.105V52.8291C104.105 74.2873 79.3899 90.9188 67.0326 96.5522C40.0791 81.9851 34.2857 61.3339 34.7581 52.8291Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M67 91L67 109" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M38.7925 120.066V126.609H95.5998V120.066C95.5998 115.531 91.3111 114.542 89.1667 114.615H45.3346C40.0137 114.615 38.7562 118.249 38.7925 120.066Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M33.8857 3H104.868" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                    </svg>
                    Habit Tracking
                    <ul class="w-100">
                        <li>
                            <select v-model="params.habit_id">
                                <option :value="habit.id" v-for="(habit, h) of habits" :key="h">{{ habit.name }}
                                </option>
                            </select>
                            <span><i class="fas fa-angle-down"></i></span>
                        </li>
                    </ul>
                </div>
                <quote-loader v-if="habitLoader" />
                <ul class="rankboard" v-else>
                    <li v-for="(participant, p) in habitLeaderboard.overallContacts" :key="p">
                        <span class="board_pos">{{ p + 1 }}
                            <svg width="140" height="118" viewBox="0 0 140 118" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="p < 3">
                                <path d="M0 118V0H140V118L70 61L0 118Z" fill="#2f7eed"/>
                            </svg>
                        </span>
                        <img :src="participant.contact.photo" :alt="participant.contact.name">
                        <h5>{{ participant.contact.name }}</h5>
                        <div class="score_circle">
                            <div class="inner_circle">
                                <div class="score">{{ participant.overall_score }}<span>%</span></div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" :stroke="clientPortal.chart_settings.card_accent_color" :stroke-dasharray="90" :stroke-dashoffset="90 - (participant.overall_score * 0.9)" />
                            </svg>
                        </div>
                    </li>
                    <li v-if="habitLeaderboard.overallContacts && habitLeaderboard.overallContacts.length == 0" class="text-center">
                        No records found!
                    </li>
                </ul>
            </div>
            <div class="quick_card" v-if="1 == 2">
                <div class="title">
                    <svg width="139" height="127" viewBox="0 0 139 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.3437 64.4958C17.6032 56.936 -0.765599 38.0874 3.68304 23.1714C4.95512 19.2098 10.7704 11.2866 23.8546 11.2866H34.7581H103.886H114.463C120.133 11.2139 132.17 13.4891 134.961 23.1714C138.451 35.2743 127.983 50.2121 111.628 64.4958" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M34.7581 52.8291V11.3958H104.105V52.8291C104.105 74.2873 79.3899 90.9188 67.0326 96.5522C40.0791 81.9851 34.2857 61.3339 34.7581 52.8291Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M67 91L67 109" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M38.7925 120.066V126.609H95.5998V120.066C95.5998 115.531 91.3111 114.542 89.1667 114.615H45.3346C40.0137 114.615 38.7562 118.249 38.7925 120.066Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M33.8857 3H104.868" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                    </svg>
                    Client Tracking
                    <ul class="w-100">
                        <li>
                            <select v-model="params.progress_id">
                                <option :value="progress.id" v-for="(progress, p) of progresses" :key="p">{{ progress.name }}
                                </option>
                            </select>
                            <span><i class="fas fa-angle-down"></i></span>
                        </li>
                    </ul>
                </div>
                <quote-loader v-if="progressLoader" />
                <ul class="rankboard" v-else>
                    <li v-for="(participant, p) in progressLeaderboard.overallContacts" :key="p">
                        <span class="board_pos">{{ p + 1 }}
                            <svg width="140" height="118" viewBox="0 0 140 118" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="p < 3">
                                <path d="M0 118V0H140V118L70 61L0 118Z" fill="#2f7eed"/>
                            </svg>
                        </span>
                        <img :src="participant.contact.photo" :alt="participant.contact.name">
                        <h5>{{ participant.contact.name }}</h5>
                        <div class="score_circle">
                            <div class="inner_circle">
                                <div class="score">{{ participant.overall_score }}<span>%</span></div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" :stroke="clientPortal.chart_settings.card_accent_color" :stroke-dasharray="90" :stroke-dashoffset="90 - (participant.overall_score * 0.9)" />
                            </svg>
                        </div>
                    </li>
                    <li v-if="progressLeaderboard.overallContacts && progressLeaderboard.overallContacts.length == 0" class="text-center">
                        No records found!
                    </li>
                </ul>
            </div>
            <div class="quick_card" v-if="1 == 2">
                <div class="title">
                    <svg width="139" height="127" viewBox="0 0 139 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27.3437 64.4958C17.6032 56.936 -0.765599 38.0874 3.68304 23.1714C4.95512 19.2098 10.7704 11.2866 23.8546 11.2866H34.7581H103.886H114.463C120.133 11.2139 132.17 13.4891 134.961 23.1714C138.451 35.2743 127.983 50.2121 111.628 64.4958" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M34.7581 52.8291V11.3958H104.105V52.8291C104.105 74.2873 79.3899 90.9188 67.0326 96.5522C40.0791 81.9851 34.2857 61.3339 34.7581 52.8291Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M67 91L67 109" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                        <path d="M38.7925 120.066V126.609H95.5998V120.066C95.5998 115.531 91.3111 114.542 89.1667 114.615H45.3346C40.0137 114.615 38.7562 118.249 38.7925 120.066Z" :fill="clientPortal.chart_settings.card_accent_color" />
                        <path d="M33.8857 3H104.868" :stroke="clientPortal.chart_settings.card_accent_color" stroke-width="5" stroke-linecap="round" />
                    </svg>
                    Workout Plan
                    <ul class="w-100">
                        <li>
                            <select v-model="params.workout_plan_id">
                                <option :value="plan.id" v-for="(plan, p) of workoutPlans" :key="p">{{ plan.name }}
                                </option>
                            </select>
                            <span><i class="fas fa-angle-down"></i></span>
                        </li>
                    </ul>
                </div>
                <quote-loader v-if="workoutPlanLoader" />
                <ul class="rankboard" v-else>
                    <li v-for="(participant, p) in workoutPlanLeaderboard" :key="p">
                        <span class="board_pos">{{ p + 1 }}
                            <svg width="140" height="118" viewBox="0 0 140 118" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="p < 3">
                                <path d="M0 118V0H140V118L70 61L0 118Z" fill="#2f7eed"/>
                            </svg>
                        </span>
                        <img :src="participant.contact.photo" :alt="participant.contact.name">
                        <h5>{{ participant.contact.name }}</h5>
                        <div class="score_circle">
                            <div class="inner_circle">
                                <div class="score">{{ participant.overall_score }}<span>%</span></div>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30">
                                <circle cx="15" cy="15" r="14" stroke-linecap="round" fill="none" stroke-width="2px" :stroke="clientPortal.chart_settings.card_accent_color" :stroke-dasharray="90" :stroke-dashoffset="90 - (participant.overall_score * 0.9)" />
                            </svg>
                        </div>
                    </li>
                    <li v-if="workoutPlanLeaderboard.length == 0" class="text-center">
                        No records found!
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
    name: 'leaderboard-ranks',

    data() {
        return {
            params: {
                habit_id: 0,
                progress_id: 0,
                workout_plan_id: 0,
            },
            workoutPlanLeaderboard: [],
            progressLoader: false,
            habitLoader: false,
        };
    },

    props: {
        clientPortal: Object
    },

    watch: {
        'params.progress_id' (id) {
            const vm = this;

            vm.getProgressLeaderboard({ id });
        },

        'params.habit_id' (id) {
            const vm = this;

            vm.getHabitLeaderboard({ id });
        },

        'params.workout_plan_id' (id) {
            const vm = this;

            vm.getWorkoutPlanLeaderboard(id);
        },

        progresses (progresses) {
            const vm = this;

            vm.params.progress_id       = progresses[0].id;
        },

        habits (habits) {
            const vm = this;

            vm.params.habit_id       = habits[0].id;
        },

        workoutPlans (workoutPlans) {
            const vm = this;

            vm.params.workout_plan_id       = workoutPlans[0].id;
        },
    },

    computed: mapState({
        progresses: state => state.progressTrackingModule.allProgressTrackings,
        habits: state => state.habitTrackingModule.allHabitTrackings,
        workoutPlans: state => state.workoutModule.allWorkoutPlans,
        progressLeaderboard: state => state.progressTrackingModule.leaderboard,
        habitLeaderboard: state => state.habitTrackingModule.leaderboard,
        habitTrackingLoader: state => state.habitTrackingModule.habitTrackingLoader,
        progressTrackingLoader: state => state.progressTrackingModule.progressTrackingLoader,
        workoutPlanLoader: state => state.workoutModule.allWorkoutPlanLoader,
    }),

    mounted () {
        const vm = this;

        if (!vm.progresses.length) {
            vm.getAllProgressTrackings();
        }

        if (!vm.habits.length) {
            vm.getAllHabitTrackings();
        }

        if (!vm.workoutPlans.length) {
            vm.getAllWorkoutPlans();
        }
    },

    methods: {
        ...mapActions({
            getAllProgressTrackings: 'progressTrackingModule/getAllProgressTrackings',
            getAllHabitTrackings: 'habitTrackingModule/getAllHabitTrackings',
            getAllWorkoutPlans: 'workoutModule/getAllWorkoutPlans',
            getProgressLeaderboard: 'progressTrackingModule/getProgressLeaderboard',
            getHabitLeaderboard: 'habitTrackingModule/getHabitLeaderboard',
        }),

        getWorkoutPlanLeaderboard (id) {

        },
    }
}
</script>

<style scoped>
.dashboard_content h3{
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 15px;
    justify-content: flex-start;
    align-items: center;
}
:deep(.quote_wpr) {
    min-height: 250px;
    justify-content: center;
}

li.text-center {
    justify-content: center;
}

.empty_box {
    padding: 20px 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}
.empty_box img {
    max-height: 90px;
    width: auto;
    max-width: 100%;
    margin-bottom: 30px;
}
.empty_box p {
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    color: #2c3e50;
    margin-top: auto;
}
.empty_box h2 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #2c3e50;
}
.fx_height {
    min-height: 180px;
    padding-bottom: 25px;
}
.empty_box.fx_height h2 {
    margin: auto 0;
}
.empty_box.fx_height p {
    margin-top: 20px;
}
</style>